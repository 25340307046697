@import "ckeditor5/ckeditor5.css";

.page,
.main {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.main {
  padding: 30px 30px 30px 270px;
  transition: all 0.2s ease-in-out;
}

.icon-action {
  font-size: 20px;
  cursor: pointer;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-middle {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}
.justify-content-end {
  justify-content: end;
}
.line-height {
  line-height: 24px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.d-block {
  width: 100%;
}
.pb-20 {
  padding-bottom: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.is-submenu .sub-menu {
  width: 220px;
  left: 241px;
}
.is-submenu .main {
  padding-left: 480px;
}

.capitalize {
  text-transform: capitalize;
}
.capitalize-first:first-letter {
  text-transform: capitalize;
}
.ant-modal-title {
  font-weight: 700;
}
.ant-table-tbody > tr > td.nz-disable-td {
  border-bottom: none;
}
.cursor-pointer {
  cursor: pointer;
}
strong {
  font-weight: bold;
}
.company-about a strong {
  color: inherit;
}
textarea {
  resize: none;
  overflow: hidden;
}

.font-medium {
  font-weight: 500;
}

.font-size-medium {
  font-size: 14px;
}
.font-weight-medium {
  font-weight: 600;
}
.font-weigth-sm-medium {
  font-weight: 500;
}
.font-sm {
  font-size: 12px;
}

.no-border-radius .ant-modal-content {
  border-radius: 0;
}
.centered-title-modal .ant-modal-title {
  text-align: center;
  width: 100%;
}
.centered-title-modal .ant-modal-close-x {
  font-size: 14px;
  margin-right: 14px;
}

.separator-lg {
  margin-bottom: 24px;
}
.bg-white {
  background-color: #fff;
}
.desktop-view {
  height: 100vh;
  overflow: hidden;
}
.scrollable {
  max-height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}
/*
 *  scrollbar
 */

.scrollable::-webkit-scrollbar-track {
  border-radius: 12;
  background-color: transparent;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  border-radius: 12;
  background-color: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #e5e4ed;
}
.w-full {
  width: 100%;
}
.pr-16 {
  padding-right: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.position-relative {
  position: relative;
}
.custom-sub-menu-list {
  max-height: 400px;
  overflow-y: auto;
  .ant-checkbox + span {
    max-width: 480px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
}

.ant-typography-copy {
  margin-left: 0 !important;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.no-padding {
  padding: 0 !important;
}
.editor-content {
  width: 100%; /* Adjust width as needed */
}
.fiter-form .ant-select-multiple .ant-select-selection-item-content {
  width: 80px;
}
.impersonating {
  padding-top: 80px;
}
.tags-list .badge {
  display: flex;
  margin: 2px;
}
.badge {
  display: inline-block;
  padding: 2px 10px;
  height: 24px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  text-wrap: nowrap;
  svg {
    margin-left: 5px;
  }
}

.ant-table-tbody > tr:last-child td {
  border-bottom: none;
}

.ico-light.bookmarked svg {
  fill: #facc15;
  stroke: #facc15;
}
.ico-light.bookmarked svg path {
  fill: #facc15;
  stroke: #facc15;
}

.ico-light.bookmarked svg path {
  fill: #facc15;
  fill-opacity: 1;
}
.filters-wrap {
  margin-bottom: 24px;
}
.mb-12 {
  margin-bottom: 12px;
}
.filter-dropdown {
  min-width: 200px;
  font-size: 14px;
}
.clear-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  cursor: pointer;
  color: #dc2626;
}
.filter-search-group {
  display: flex;
  align-items: center;
  padding: 0 12px;
  .search-icon {
    margin: 3px 0 0 5px;
  }
}
.checkboxes-wrap {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 12px;
  .ant-checkbox-wrapper {
    display: flex;
    margin: 0 0 8px 0;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
.checkboxes-wrap {
  padding: 0 12px;
  .ant-checkbox-group-item {
    display: flex;
    margin: 0 0 8px 0;
  }
}
.count-tag {
  display: inline-block;
  padding: 0px 2px;
  margin: 0 2px 0 5px;
  height: 20px;
  width: 24px;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  background-color: #7d1e68;
}
.apexcharts-legend-marker {
  width: 8px !important;
  height: 8px !important;
  margin-right: 5px !important;
}
.ant-card-grid {
  cursor: default !important;
}
.bordered-radius-left {
  border-radius: 6px 0 0 6px !important;
}
.bordered-radius-right {
  border-radius: 0 6px 6px 0 !important;
}
.ant-dropdown-menu {
  min-width: 200px;
  padding: 4px !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  border-radius: 6px;
}
.break-email {
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}
.table-data,
.ant-table-content {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.apexcharts-bar-area[index="0"] {
  clip-path: inset(0% 0% 0% 0% round 0px 0px 8px 8px);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 960px) {
  .main {
    padding: 15px;
  }
  .is-submenu .sub-menu {
    width: 180px;
    left: 201px;
  }
  .is-submenu .main {
    padding-left: 15px;
  }
}
